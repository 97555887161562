import "./App.scss";
import { Route, Routes } from "react-router-dom";
import Home from "./Main/Home";
import ServiceRequest from "./ServiceRequest/ServiceRequest";
import ErrorPage from "./ErrorPage";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./navbar.component/navbar";
import { ToastContainer } from "react-toastify";
import UpdateServiceRequest from "./components/UpdateServiceRequest/updateServiceRequest";
import ServiceRequestListing from "./serviceRequestListing/serviceRequestListing";
import ServiceRequestTable from "./serviceRequest-table.component/ServiceRequest_table";
import ServiceRequestSlice from "./store/service-request/service-request.slice";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./store";
import {
  ITokenDetails,
  IValidateTokenPayload,
} from "./store/keycloak/asset-keycloak.interface";
import {
  ASSET_SUITE_LOGIN_URL,
  IFRAME_ID,
  KEYCLOAK_GRANT_TYPES,
  SESSION_KEY,
  SESSION_TYPE,
} from "./config/default.config";
import { encryptUtils } from "./utils/encryption.utils";
import { validateToken } from "./store/keycloak/asset-keycloak.action";
import { setLogin } from "./store/seqr-ops/seqr-ops.slice";
function App() {
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let tokenDataEncrypted: any = localStorage.getItem(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if (tokenData !== null) {
      setReady(true);
      let data = tokenData;
      const ITokenDetails: ITokenDetails = {
        auth_token: data.token?.auth_token,
        refresh_token: data.token?.refresh_token,
        client_id: data.token?.client_id,
        client_secret: data.token?.client_secret,
        username: data.token?.username,
        password: data.token?.password,
      };
      const IValidateTokenPayload: IValidateTokenPayload = {
        token: data.token?.auth_token,
        client_id: data.token?.client_id,
        client_secret: data.token?.client_secret,
        username: data.token?.username,
        password: data.token?.password,
        grant_type: KEYCLOAK_GRANT_TYPES.PASSWORD,
      };
      dispatch(setLogin(data?.token?.isLoggedIn));
      dispatch(
        validateToken(
          IValidateTokenPayload,
          data.token?.refresh_token,
          ITokenDetails
        )
      );
    } else {
      let rl = encryptUtils.encryptURL(SESSION_KEY.FSM_KEY);
      let postMsg = {
        [SESSION_KEY.REDIRECT_LOCATION_KEY]: rl,
        type: SESSION_TYPE.USER_LOGIN_TYPE,
      };
      const POST_DOMAIN_DATA = [
        {
          frameId: IFRAME_ID.SUITE_IFRAME_ID,
          url: `${process.env.REACT_APP_SUITE_URL}`,
        },
      ];
      for (let i = 0; i < POST_DOMAIN_DATA.length; i++) {
        console.log("cross domain test in qa fsm ::: ", POST_DOMAIN_DATA[i]);
        const iframe = document.getElementById(
          POST_DOMAIN_DATA[i].frameId
        ) as HTMLIFrameElement;
        iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA[i].url);
      }
      window.location.href = ASSET_SUITE_LOGIN_URL;
    }
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <>
      <div className="dv">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/addServiceRequest" element={<ServiceRequest />} />
        <Route
          path="/updateServiceRequest/:id"
          element={<UpdateServiceRequest />}
        />
        <Route
          path="/serviceRequestListing"
          element={<ServiceRequestListing />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        limit={1}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
