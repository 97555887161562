import {
  IAddServiceRequestReq,
  ICommentsPayload,
  IServiceRequestDetailsById,
  IUpdateServiceRequestReq,
  getAllInstances,
  getAllServiceList,
  getFilterData,
} from "./service-request.interface";
import { default as request } from "axios";
import { toasterUtils } from "../../utils/toaster.utils";
import { MESSAGES } from "../../config/default.config";
import { serviceRequestService } from "./service-request.service";
import { useNavigate } from "react-router-dom";
import { AppThunk } from "..";
const addServiceRequest = async (
  IAddServiceRequestReq: IAddServiceRequestReq
) => {
  console.log("IAddServiceRequestReq in Action ::: ", IAddServiceRequestReq);
  try {
    const response = await serviceRequestService.addServiceRequest(
      IAddServiceRequestReq
    );
    if (response.success) {
      toasterUtils.showSuccess(MESSAGES.SERVICE_REQUEST_CREATED_SUCCESFULLY);
      return true;
    } else {
      toasterUtils.showError(MESSAGES.SERVICE_REQUEST_CREATION_FAILED);
      return false;
    }
  } catch (error) {
    toasterUtils.showError(MESSAGES.SERVICE_REQUEST_CREATION_FAILED);
    return false;
  }
};
const deleteServiceRequestById = (id:any) => {
  return async () => {
    try {
      const response =await serviceRequestService.deleteServiceRequestById(id);
      if (response) {
        toasterUtils.showSuccess(MESSAGES.SERVICE_REQUEST_DELETED_SUCCESFULLY);
        return true;
      } else {
        toasterUtils.showError(MESSAGES.SERVICE_REQUEST_DELETED_FAILED);
        return false;
      }
    } catch (error) {
    }
  };
};

const updateServiceRequest = async (
  IUpdateServiceRequestReq: IUpdateServiceRequestReq
) => {
  console.log("IUpdateServiceRequestReq :::", IUpdateServiceRequestReq);
  try {
    const response = await serviceRequestService.updateServiceRequest(
      IUpdateServiceRequestReq
    );
    console.log("After IUpdateServiceRequestReq :::", IUpdateServiceRequestReq);
    if (response.success) {
      toasterUtils.showSuccess(MESSAGES.SERVICE_REQUEST_UPDATED_SUCCESFULLY);
      return true;
    } else {
      toasterUtils.showError(MESSAGES.SERVICE_REQUEST_UPDATED_FAILED);
      return false;
    }
  } catch (error) {
    toasterUtils.showError(MESSAGES.SERVICE_REQUEST_UPDATED_FAILED);
    return false;
  }
};
const getAllServiceRequest = async () => {
  try {
    const response = await serviceRequestService.getAllServiceRequest();
    if (response.success) {
      return response.data;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getServiceRequesDetailsById = async (
  id: number
): Promise<IServiceRequestDetailsById | undefined> => {
  try {
    const response = await serviceRequestService.getServiceRequestById(id);
    return response.data || undefined;
  } catch (error) {
    if (request.isAxiosError(error) && error.response) {
      toasterUtils.showError(MESSAGES.SOMETHING_WENT_WRONG);
    }
  }
  return undefined;
};

const getPriorityCount = async () => {
  return await serviceRequestService
    .getPriorityCount()
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};
const getOverdueCount = async () => {
  return await serviceRequestService
    .getOverdueCount()
    .then((response) => {
      console.log("overdueeeeeeeeee ::: ", response);
      return response;
    })
    .catch((error) => {
      console.log("Error in taking the overdue count", error);
    });
};

// Pagination
const getAllServiceLists = async (getAllServiceList: getAllServiceList) => {
  try {
    const response = await serviceRequestService.getAllServiceLists(
      getAllServiceList
    );

    if (response.success) {
      return response.data;
    } else {
      return 0;
    }
  } catch (error: any) {
    console.log("errorr in pagination");
    return 0;
  }
};
const getFilterDatas =async(getFilterData:getFilterData)=>{
  try{
    const response =await serviceRequestService.getFilterDatas(
      getFilterData
    );
    if (response) {
      console.log("done in action filetered listttttttttt :::  ", response.data)
      return response.data;
    } else {
      return 0;
    }
  } catch (error: any) {
    console.log("errorr in pagination");
    return 0;
  }
} 
const fileUpload = async (
  files: any,
  property: any,
  setValue: any,
  setSuccess: (state: boolean) => void
) => {
  try {
    const references = [];
    for (let i = 0; i < files.length; i++) {
      const response = await serviceRequestService.getReference();
      if (response) {
        const reference = response.data.data;
        references.push(reference);
        const formData = new FormData();
        formData.append("file", files[i]);
        formData.append("reference", reference);

        const response1 = await serviceRequestService.fileUpload(formData);

        if (response1) {
          const uploadResponse = response1.data.data.url;
          console.log(
            "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",
            uploadResponse
          );
          const updatedPropertyValues = { ...property };
          updatedPropertyValues.attachmentModel.push({
            attachment: uploadResponse,
          });
          setValue("attachmentModel", updatedPropertyValues.attachmentModel);
          setSuccess(true);
        } else {
          return 0;
        }
      }
    }
    return references;
  } catch (error) {
    console.log("Error in getting reference:", error);
    return 0;
  }
};

const getAllEquipmentModels = async () => {
  try {
    const response = await serviceRequestService.getAllEquipmentModels();
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getAllInstancess = async (getAllInstances: getAllInstances) => {
  try {
    const response = await serviceRequestService.getAllInstancess(
      getAllInstances
    );
    if (response) {
      console.log("Its actionnnnnnnnnnnnnnnnnnnnnnnn nokkkk", response);
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getServiceRequestCommentsById = async (requestId: number) => {
  try {
    const response = await serviceRequestService.getServiceRequestCommentsById(
      requestId
    );
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

// const getKeyClockBearerToken = async () => {
//   try {
//     const response = await serviceRequestService.getKeyClockBearerToken();
//     console.log("key clock action ::::", response.access_token);
//     return response;
//   } catch (error) {
//     console.log("errrrrrror");
//   }
// };
const getKeyClockBearerToken = async () => {
  try {
    const response = await serviceRequestService.getKeyClockBearerToken();
    // console.log("key clock action ::::", response.access_token);
    const userListResponse = await serviceRequestService.getKeyClockUserList(
      response.access_token
    ); // Pass the access token as an argument
    console.log("User List Response in :", userListResponse);
    return userListResponse;
  } catch (error) {
    console.log("errrrrrror");
  }
};

const addComments = async (ICommentsPayload : ICommentsPayload) => {
  try {
    const response = await serviceRequestService.addComments(
      ICommentsPayload
    );
    if (response) {
      console.log("Its action comments", response);
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const getCustomers = async () => {
  try {
    const response = await serviceRequestService.getCustomers();
    if (response) {
      return response;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};


export const serviceRequestAction = {
  addServiceRequest,
  getAllServiceRequest,
  getServiceRequesDetailsById,
  getServiceRequestCommentsById,
  updateServiceRequest,
  getPriorityCount,
  getAllServiceLists,
  addComments,
  fileUpload,
  getAllEquipmentModels,
  getAllInstancess,
  getOverdueCount,
  getKeyClockBearerToken,
  getFilterDatas,
  getCustomers,
  deleteServiceRequestById,
};
