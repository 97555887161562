// import { Autocomplete, TextField } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { serviceRequestAction } from "../../store/service-request/service-request.action";

// function EquipmentModelComponent({
//   register,
//   setValue,
//   setEquipmentModel,
// }: {
//   register: any;
//   setValue: any;
//   setEquipmentModel: any;
// }) 
// {
// const [states, setStates] = useState<any>([]);


// useEffect(() => {
//     serviceRequestAction.getAllEquipmentModels().then((response: any) => {
//       console.log("Equipment model :::", response)
//       setStates(response);
//     });
//   },[]);  
//   return (
//     <>
//       <Autocomplete
//         options={states?states:[]}
//         onChange={(e: any, data: any) => {
//           setEquipmentModel(data);
//           setValue("equipment_model", data);
//         }}
//         getOptionLabel={(option: any) => option}
//         renderInput={(params: any) => {
//           return <TextField {...params} key={params.id} label="" />;
//         }}
//       />
//     </>
//   );
// }

// export default EquipmentModelComponent;


import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { MESSAGES } from "../../config/default.config";

function EquipmentModelComponent({
  register,
  setValue,
  setEquipmentModel,
  errorWrap,
  formState,
  clearErrors,

}: {
  register: any;
  setValue: any;
  setEquipmentModel: any;
  errorWrap:any;
  formState:any;
  clearErrors:any;

}) 
{
const [states, setStates] = useState<any>([]);
const errors = formState.errors;


useEffect(() => {
    serviceRequestAction.getAllEquipmentModels().then((response: any) => {
      console.log("Equipment model :::", response)
      setStates(response);
    });
  },[]);  
  return (
    <>
      <Autocomplete
        options={states?states:[]}
        {...register("equipment_model", {
          required: MESSAGES.EQUIPMENT_MODEL_REQUIRED,
        })}
        onChange={(e: any, data: any) => {
          setEquipmentModel(data);
          setValue("equipment_model", data);
          clearErrors("equipment_model");

        }}
        getOptionLabel={(option: any) => option}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.equipment_model?.message)}

    </>
  );
}

export default EquipmentModelComponent;


  