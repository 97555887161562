import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "@mui/material";
import { serviceRequestAction } from "../store/service-request/service-request.action";
import { IGetAllServiceRequest } from "../store/service-request/service-request.interface";
import "./ServiceRequest_table.scss";
import { useNavigate } from "react-router-dom";
import attachment from "../assest/images/attachment-icon.svg";
import { request } from "http";

function ServiceRequestTable({
  filteredData,
}:{
  filteredData: any
}) {
  const [servieRequesList, setServiceRequestList] = useState<any>([]);

  // useEffect(() => {
  //   serviceRequestAction
  //     .getAllServiceRequest()
  //     .then((response: any) => {
  //       var data = response;
  //       setServiceRequestList(data);
  //     })
  //     .catch((err: any) => {
  //       console.log("----err");
  //     });
  // }, []);
  useEffect(() => {

    if(filteredData != undefined && filteredData[0]?.id != null && filteredData[0]?.id != undefined){
      setServiceRequestList(filteredData);
    }else{
      serviceRequestAction
      .getAllServiceRequest()
      .then((response: any) => {
        var data = response;
        setServiceRequestList(data);
      })
      .catch((err: any) => {
        console.log("----err");
      });
    }
  }, [filteredData]);
  const navigate = useNavigate();
  const navigateToUpdateServiceRequest = (id: any) => {
    console.log("---------", servieRequesList);

    navigate("/updateServiceRequest/" + id);
  };
  const [update, setUpdate] = useState();

  //convertin UTC TO IND TIME FORMAT
  const formatLocalDate = (utcDate: string) => {
    const date = new Date(utcDate);
    return date.toLocaleDateString("en-IN");
  };
  return (
    <div className="seqrops-instance-table-wrapper">
      <div className="">
        <div className="seqrops-instance-table">
          <Table className="myTable">
            <thead>
              <tr>
                <th>Order</th>
                <th>Request</th>
                <th>State</th>
                <th>Created Date</th>
                <th>Due Date</th>
                <th>Priority </th>
                <th>Attachment</th>
              </tr>
            </thead>
            <tbody>
              {servieRequesList?.length > 0 &&
                servieRequesList?.map(
                  (request: IGetAllServiceRequest, index: number) => {
                    console.log(" request :::111 ", request);

                    return (
                      <>
                        <tr
                          onClick={() => {
                            navigateToUpdateServiceRequest(request.id);
                          }}
                          key={request.id}
                        >
                          <td>{index + 1}</td>
                          <td>{request.request}</td>
                          <td>
                            {request.state === "Closed" ? (
                              <span className="greenIndicator"></span>
                            ) : request.state === "New" ? (
                              <span className="redIndicator"></span>
                            ) : (
                              <span className="orangeIndicator"></span>
                            )}
                            {/* {request.state} */}
                            {request.state === "Inprogress" ? "In Progress" : request.state}
                          </td>
                          <td>{formatLocalDate(request.created)}</td>
                          <td>{formatLocalDate(request.dueDate)}</td>
                          <td>{request.priority}</td>
                          <td>
                            {request?.attachmentModel?.length > 0 ? (
                              <span className="attachment_icon">
                                <img src={attachment} alt="Attachment Image" />
                              </span>
                            ) : null}
                          </td>
                        </tr>
                      </>
                    );
                  }
                )}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ServiceRequestTable;
