// import { Autocomplete, TextField } from "@mui/material";
// import React from "react";

// function PriorityComponent({
//   register,
//   setValue,
// }: {
//   register: any;
//   setValue: any;
// }) {
//   const states: any = [{ name: "Low" }, { name: "Medium" }, { name: " High" }];
//   return (
//     <>
//       <Autocomplete
//         options={states}
//         onChange={(e: any, data: any) => {
//           setValue("priority", data.name);
//         }}
//         getOptionLabel={(option: any) => option.name}
//         renderInput={(params: any) => {
//           return <TextField {...params} key={params.id} label="" />;
//         }}
//       />
//     </>
//   );
// }

// export default PriorityComponent;
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { MESSAGES } from "../../config/default.config";

function PriorityComponent({
  register,
  setValue,
  errorWrap,
  formState,
  clearErrors,
}: {
  register: any;
  setValue: any;
  errorWrap: any;
  formState: any;
  clearErrors:any;
}) {
  const states: any = [{ name: "Low" }, { name: "Medium" }, { name: " High" }];
  const errors = formState.errors;

  return (
    <>
      <Autocomplete
        {...register("priority", {
          required: MESSAGES.PRIORITY_REQUIRED,
        })}
        options={states}
        onChange={(e: any, data: any) => {
          setValue("priority", data.name);
          clearErrors("priority");

        }}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.priority?.message)}
    </>
  );
}

export default PriorityComponent;
