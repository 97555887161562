import React, { useEffect, useState } from "react";
import "./updateServiceRequest.scss";
import sequropsBackIcon from "../../assest/images/sequrops_back_icon.svg";
import noAttachment from "../../assest/images/no_attachment_found_icon.svg";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import StateComponent from "../../ServiceRequest/sub-components/state_component";
import { useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import ServiceCommenetsList from "../service-comments-list/service-comments-list";
import { Tooltip } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { ICommentsPayload } from "../../store/service-request/service-request.interface";
import { ConfirmDialog } from "primereact/confirmdialog";

function UpdateServiceRequest() {
  interface IServiceRequestDetailsById {
    id: number;
    equipment_id: string;
    equipment_model: string;
    customer_name: string;
    assign_to_name: string;
    service_type: string;
    priority: string;
    fix_within: number;
    discription: string;
    created: string;
    state: string;
    attachmentModel: [];
    comments: string;
    username: string;
    request: string;
  }
  
  // id taken from queryParams
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const [serviceList, setStateList] = useState<any>();
  const userName = useAppSelector((state) => state.assetKeycloak.username);
  const [userNameForComparing, setuserNameForComparing] = useState();
  const [comments, setComments] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedImage, setselectedImage] = useState();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean>(false); 
  const [visible, setVisible] = useState(false);
  const handleImageClick = (attachment1: any) => {
    setselectedImage(attachment1);
  };
  // taking data from here to state component
  <StateComponent
    serviceList={serviceList}
    register={undefined}
    handleTypeChange={function (type: string): void {
      throw new Error("Function not implemented.");
    }}
  />;

  const errorWrap = (errorMsg: string) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const serviceListById = () => {
    serviceRequestAction
      .getServiceRequesDetailsById(Number(id))
      .then((response: any) => {
        console.log(response, ":::::::::::::::::::::::");
        setStateList(response);
      });
  };
  useEffect(() => {
    serviceRequestAction
      .getServiceRequesDetailsById(Number(id))
      .then((response: any) => {
        setStateList(response);
        setuserNameForComparing(response.userName);
      })
      .catch((err: any) => {
        console.log("----err");
      });

    serviceRequestAction
      .getServiceRequestCommentsById(Number(id))
      .then((res: any) => {
        setComments(res);
      });
  }, []);

  useEffect(() => {
    if (userNameForComparing === userName) {
      setIsDisabled(true);
    }
  }, [userNameForComparing, userName]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    reset,
    clearErrors,
    formState,
  } = useForm({
    defaultValues: {
      id: id,
      equipment_id: "",
      equipment_model: "",
      customer_name: "",
      assign_to_name: "",
      service_type: "",
      priority: "",
      fix_within: 0,
      discription: "",
      created: "",
      state: "",
      attachmentModel: [],
      comments: "",
      request: "",
      instance_name: "",
    },
  });

  const handleCommentsChange = (e: any) => {
    clearErrors();
    if(e.target.value.length > 0){
      setIsValid(true);
    }else{
      setIsValid(false);
    }
  };

  const handleCommentSave = () => {
    let ICommentsPayload: ICommentsPayload = {
      id: id,
      comments: getValues().comments,
      user: userName,
    };

    serviceRequestAction
      .addComments(ICommentsPayload)
      .then(() => {
        return serviceRequestAction.getServiceRequestCommentsById(Number(id));
      })
      .then((res: any) => {
        setComments(res);
      })
      .catch((error: any) => {
        console.log("Error saving comment:", error);
      });

    clearErrors();
    setValue("comments", "");
    setIsValid(false);
  };

  const handleCancel = () =>{
    clearErrors();
    setValue("comments", "");
    setIsValid(false);
  }

  const errors = formState.errors;

  const handleSave = (propertyValues: any) => {
    if (getValues()?.comments !== "") {
      setError("comments", {
        type: "custom",
        message: "Please save the comments added",
      });
      return;
    }
    const requestBody: any = {
      id: serviceList?.id,
      equipment_id: serviceList?.equipment_id,
      equipment_model: serviceList?.equipment_model,
      customer_name: serviceList?.customer_name,
      assign_to_name: serviceList?.assign_to_name,
      service_type: serviceList?.service_type,
      priority: serviceList?.priority,
      fix_within: serviceList?.fix_within,
      discription: serviceList?.discription,
      created: serviceList?.created,
      state: serviceList?.state,
      attachmentModel: serviceList?.attachmentModel,
      comments: getValues()?.comments,
      username: userName,
      request: serviceList?.request,
    };
    if (id) {
      navigate("/");
      dispatch(serviceRequestAction.updateServiceRequest(requestBody));
    } else {
      console.error("Id is undefined");
    }
  };

  const handleTypeChange = (
    selected_option: string,
    updateTrigger: boolean = false
  ) => {
    const requestBody: IServiceRequestDetailsById = {
      id: serviceList?.id,
      equipment_id: serviceList?.equipment_id,
      equipment_model: serviceList?.equipment_model,
      customer_name: serviceList?.customer_name,
      assign_to_name: serviceList?.assign_to_name,
      service_type: serviceList?.service_type,
      priority: serviceList?.priority,
      fix_within: serviceList?.fix_within,
      discription: serviceList?.description,
      created: serviceList?.created,
      state: selected_option,
      attachmentModel: [],
      comments: "",
      username: userName,
      request: serviceList?.request,
    };

    setStateList(requestBody);
  };

  const renderState = () => {
    return (
      <StateComponent
        register={register}
        handleTypeChange={handleTypeChange}
        serviceList={serviceList}
      />
    );
  };

  const handleDelete = async (id: number) => {
    if (id) {
      navigate("/");
      dispatch(serviceRequestAction.deleteServiceRequestById(id));
      }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="headerSection d-flex my-5">
            <div className="p-1">
              <Tooltip title="Back">
                <a href="/">
                  <img src={sequropsBackIcon} alt="seqrops logo" />
                </a>
              </Tooltip>
            </div>
            <div className="px-4">
              <h2> {serviceList?.request}</h2>
            </div>

            <div className=" ms-auto d-flex">
              <div className="me-2">
                {/* <button
                  className="btn btn-outline-secondary"
                 
                  onClick={() => setShowConfirmation(true)}
                >
                  Delete
                </button> */}

                <ConfirmDialog
                  visible={showConfirmation}
                  onHide={() => setShowConfirmation(false)}
                  message="Are you sure you want to delete?"
                  header="Confirmation"
                  accept={() => {
                    handleDelete(serviceList?.id);
                    setShowConfirmation(false);
                  }}
                  reject={() => setShowConfirmation(false)}
                />
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => setShowConfirmation(true)}
                >
                  Delete
                </button>
              </div>
            <div className=" ms-auto">
              <button
                onClick={handleSubmit(handleSave)}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        </div>
        <div className="SecondLayer d-flex align-items-center">
          <div className="">
            <div className="d-flex align-items-center">
              <span className="me-3">State : </span>
              {serviceList?.state === "Closed" ? (
                <span className="greenIndicator"></span>
              ) : serviceList?.state === "Inprogress" ? (
                <span className="orangeIndicator"></span>
              ) : (
                <span className="redIndicator"></span>
              )}
              {/* <span className="state_list">{renderState()} </span>{" "} */}
              <span className={`state_list ${isDisabled ? "readonly" : ""}`}>
                {renderState()}
              </span>
            </div>
          </div>
          <div className="ms-5">
            <div>
              <span className="me-3">Priority :</span>
              {serviceList?.priority}
            </div>
          </div>
          <div className="ms-5">
            <div>
              {" "}
              <span className="me-3">Fix Within :</span>{" "}
              {serviceList?.fix_within}
            </div>
          </div>
          <div className="ms-5">
            <div>
              <span className="me-3">Assign To : </span>
              {serviceList?.assign_to_name}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="thirdLayer my-5">
            <div className="thirdLayerForm form d-flex">
              <div className="ms-3 w-100">
                <label className="form-label">Equipment Model</label>
                <input
                  className="form-control"
                  type="text"
                  value={serviceList?.equipment_model}
                  disabled={isDisabled}
                />
              </div>
              <div className="ms-3 w-100">
                <label className="form-label">Equipment</label>
                <input
                  className="form-control"
                  type="text"
                  value={serviceList?.instance_name}
                  disabled={isDisabled}
                />
              </div>
              <div className="ms-3 w-100">
                <label className="form-label">Customer</label>
                <input
                  className="form-control"
                  type="text"
                  value={serviceList?.customer_name}
                  disabled={isDisabled}
                />
              </div>
              <div className="ms-3 w-100">
                <label className="form-label">Service Type</label>
                <input
                  className="form-control"
                  type="text"
                  value={serviceList?.service_type}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="description ms-3 mt-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                value={serviceList?.description}
                disabled={isDisabled}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="ms-3">
            <label>Attachment </label>
          </div>
          <div className="imgPreview my-3">
            {serviceList?.attachmentModel?.map(
              (attachment1: any, index: any) => (
                <img
                  key={index}
                  src={attachment1.attachment}
                  alt={`Attachment ${index + 1}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    margin: "0 15px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    handleImageClick(attachment1.attachment);
                    // op.current?.toggle(e)
                    setVisible(true);
                  }}
                  
                />
              )
            )}
          </div>
          <div className="ImagePopup">
            <Dialog
              visible={visible}
              style={{ width: "41vw" }}
              onHide={() => setVisible(false)}
            >
              <img
                src={selectedImage}
                alt=""
              />
            </Dialog>
          </div>
          <div className="noAttachment">
                  <img src={noAttachment} alt="" />
          </div>
        </div> */}
        <div className="row">
          <div className="ms-3">
            <label>Attachment </label>
          </div>
          {serviceList?.attachmentModel &&
          serviceList.attachmentModel.length > 0 ? (
            <>
              <div className="imgPreview my-3">
                {serviceList.attachmentModel.map(
                  (attachment1: any, index: any) => (
                    <img
                      key={index}
                      src={attachment1.attachment}
                      alt={`Attachment ${index + 1}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        margin: "0 15px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        handleImageClick(attachment1.attachment);
                        setVisible(true);
                      }}
                    />
                  )
                )}
              </div>
              <div className="ImagePopup">
                <Dialog
                  visible={visible}
                  style={{ width: "41vw" }}
                  onHide={() => setVisible(false)}
                >
                  <img src={selectedImage} alt="" />
                </Dialog>
              </div>
            </>
          ) : (
            <div className="noAttachment">
              <img src={noAttachment} alt="" />
            </div>
          )}
        </div>
        <div className="service-commments-listing-section">
          <span className="comment-heading-section ms-3">Comments</span>
          <div className="comment-input-section">
            <div className="description ms-3 mt-3">
              <textarea
                className="form-control"
                {...register("comments", {})}
                placeholder="Add a comment"
                onChange={(e: any)=>{
                  handleCommentsChange(e)
                }}
              />
              {errorWrap(errors?.comments?.message || "")}
            </div>
            <div className="div">

            <button className={` ${!isValid ? 'btn btn-secondary save-btn' : 'btn btn-primary save-btn'}`} onClick={handleCommentSave} disabled={!isValid}>
              Save
            </button>
            <button className={`${!isValid ? 'btn btn-secondary cancel-btn' : 'btn btn-primary cancel-btn'}`} onClick={handleCancel} disabled={!isValid}>
              Cancel
            </button>
            </div>
          </div>
          <ServiceCommenetsList comments={comments} />
        </div>
      </div>
    </>
  );
}

export default UpdateServiceRequest;
