import React, {useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { serviceRequestAction } from "../store/service-request/service-request.action";
import { IAddServiceRequestReq } from "../store/service-request/service-request.interface";
import { useDispatch } from "react-redux";
import "./ServiceRequest.scss";
import sequropsBackIcon from "../assest/images/sequrops_back_icon.svg";
import { useNavigate } from "react-router-dom";
import attachment from "../assest/images/attachment-icon.svg";
import closeButton from "../assest/images/popup_close_icon.svg";
import { useAppDispatch, useAppSelector } from "../store";
import ServiceTypeComponent from "./sub-components/serviceType_component";
import PriorityComponent from "./sub-components/priority_component";
import FixWithComponent from "./sub-components/fixWith_component";
import CreateInstancePopUp from "../modules/fsm-crud/create-service-comment/create-comment";
import EquipmentIdComponent from "./sub-components/equipmentId_component";
import EquipmentModelComponent from "./sub-components/equipmentModel_component";
import AssignToComponent from "./sub-components/assignTo_component";
import { Tooltip } from "@mui/material";
import { MESSAGES } from "../config/default.config";
import CustomerComponent from "./sub-components/customer_component";

function ServiceRequest() {
  const navigate = useNavigate();
  // const useappDispatch = useAppDispatch();
  const userName = useAppSelector((state) => state.assetKeycloak.username);
  // const [addCommentPopUp, setAddCommentPopUp] = useState<boolean>(false);
  // const navigateToServiceRequest = () => {
  //   navigate("/");
  // };
  const dispatch = useDispatch<any>();
  const [equipmentModel, setEquipmentModel] = useState();
  const { register, handleSubmit, setValue, setError, getValues, formState,clearErrors } =
    useForm({
    defaultValues: {
      equipment_id: "",
      equipment_model: "",
      customer_name: "",
      assign_to_name: "",
      service_type: "",
      priority: "",
      fix_within: 0,
      description: "",
      created: "",
      state: "New",
      attachmentModel: [],
      comments: "",
      request: "",
      dueDate: "",
      instance_name:"",
    },
  });

  const [files, setFiles] = useState([]);
  const [imageNames, setImageNames] = useState<string[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const errors = formState.errors;
  const [instance_id, setinstance_id]=useState();
  const handleFileChange = (event: any) => {
    const selectedFiles = event.target.files;
    const names = [];
    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      names.push(selectedFiles[i].name);
      previews.push(URL.createObjectURL(selectedFiles[i])); // Generate preview URL
    }
    setFiles(selectedFiles);
    setImageNames(names);
    setImagePreviews(previews);
  };
  const removeImage = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);

    const updatedNames = [...imageNames];
    updatedNames.splice(index, 1);
    setImageNames(updatedNames);
  };
  const handleSave = async (propertyValues: any) => {
    console.log(propertyValues);
    try {
      if (files.length > 0) {
        //await getReference();
        dispatch(
          serviceRequestAction.fileUpload(
            files,
            propertyValues,
            setValue,
            setSuccess
          )
        );
      } else {
        setSuccess(true);
      }
    } catch (error) {
      console.error("Error saving service request:", error);
    }
  };
  const errorWrap = (errorMsg: string | undefined) => {
    if (!errorMsg) return null;
    return <p className="error-message-section">{errorMsg}</p>;
  };

  const setSuccess = (state: boolean) => {
    if (state) {
      let IAddServiceRequestReq: IAddServiceRequestReq = {
        equipment_id: instance_id || "",
        username: userName,
        equipment_model: getValues().equipment_model,
        customer_name: getValues().customer_name,
        assign_to_name: getValues().assign_to_name,
        service_type: getValues().service_type,
        priority: getValues().priority,
        fix_within: getValues().fix_within,
        description: getValues().description,
        created: getValues().created,
        request: getValues().request,
        state: getValues().state,
        attachmentModel: getValues().attachmentModel,
        dueDate: "",
        instance_name: getValues().instance_name,
      };
      navigate("/");
      dispatch(serviceRequestAction.addServiceRequest(IAddServiceRequestReq));
    }
  };

  const renderServiceType = () => {
    return <ServiceTypeComponent register={register} setValue={setValue} errorWrap={errorWrap}
    formState={formState} clearErrors={clearErrors} />;
  };
  const renderCustomer = () => {
    return <CustomerComponent register={register} setValue={setValue} errorWrap={errorWrap}
      formState={formState} clearErrors={clearErrors} />;
  }
  const renderPriority = () => {
    return <PriorityComponent register={register} setValue={setValue} errorWrap={errorWrap}
    formState={formState} clearErrors={clearErrors}/>;
  };
  const renderFixWithin = () => {
    return <FixWithComponent register={register} setValue={setValue} errorWrap={errorWrap}
    formState={formState} clearErrors={clearErrors}/>;
  };
  const renderEquipmentModel = () => {
    return (
      <EquipmentModelComponent
        register={register}
        setValue={setValue}
        setEquipmentModel={setEquipmentModel}
        errorWrap={errorWrap}
        formState={formState}
        clearErrors={clearErrors}
      />
    );
  };
  const renderEquipment = () => {
    return (
      <EquipmentIdComponent
        register={register}
        setValue={setValue}
        equipmentModel={equipmentModel}
        errorWrap={errorWrap}
        formState={formState}
        clearErrors={clearErrors}
      />
    );
  };
  const renderAssignTo = () => {
    return <AssignToComponent register={register} setValue={setValue} errorWrap={errorWrap}
    formState={formState} clearErrors={clearErrors} />;
  };
  const getAllInstancesss ={
    pageNo: 0,
    pageSize: 5,
    sortKey: "name",
    sortOrder: "asc",
    searchKey: "",
    modelName: equipmentModel|| "",
    getAll: true
  }
  useEffect(() => {
    serviceRequestAction.getAllInstancess(getAllInstancesss).then((response: any) => {
      const ids= response.data[0].id;
      setinstance_id(ids)
    });
  });
  return (
    <>
      <div className="container serviceRequest">
        <div className="header d-flex my-5">
          <div className="p-1">
            <Tooltip title="Back">
              <a href="/">
                <img src={sequropsBackIcon} alt="seqrops logo" />
              </a>
            </Tooltip>
          </div>
          <div className="px-4">
            <h2>Create Service Request</h2>
          </div>
          <div className="saveButton ms-auto">
            <button
              onClick={handleSubmit(handleSave)}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="serivceRequest-add  ">
            <div className="d-flex justify-content-between">
              <div className="Equipment_Model_dropdown w-100 me-2">
                <label className="form-label ">Equipment Model</label>
                {/* <input
                  className="form-control"
                  type="text"
                  {...register("equipment_model", {
                    pattern: {
                      value: /^\w+$/,
                      message: "",
                    },
                  })}
                /> */}
                <span>{renderEquipmentModel()}</span>
              </div>
              <div className="Equipment_dropdown w-100 me-2">
                <label className="form-label ">Equipment</label>
                {/* <input
                  className="form-control"
                  type="text"
                  {...register("equipment_id", {
                    pattern: {
                      value: /^\w+$/,
                      message: "",
                    },
                  })}
                /> */}
                <span>{renderEquipment()}</span>
              </div>

              <div className="Customer_dropdown w-100 me-2">
                <label className="form-label">Customer</label>
                {/* <input
                  className="form-control"
                  type="text"
                  {...register("customer_name", {
                    required: MESSAGES.CUSTOMER_NAME_REQUIRED,
                    pattern: {
                      value: /^[\w\s-]+$/,
                      message: "",
                    },
                  })}
                /> */}

                <span>{renderCustomer()}</span>
                {/* {errorWrap(errors?.customer_name?.message)} */}
              </div>
              <div className="AssignTo_dropdown w-100 ">
                <label className="form-label">Assign To</label>
                {/* <input
                  className="form-control"
                  type="text"
                  {...register("assign_to_name", {
                    pattern: {
                      value: /^\w+$/,
                      message: "",
                    },
                  })}
                /> */}
                <span>{renderAssignTo()}</span>
              </div>
            </div>

            <div className="d-flex justify-content-between mt-3">
            <div className="w-100 me-2">
                <label className="form-label">Request Name</label>
                <input
                  className="form-control"
                  type="text"
                  {...register("request", {
                    required: MESSAGES.REQUEST_NAME_REQUIRED,
                    pattern: {
                      value: /^[\w\s-]+$/,
                      message: "",
                    },
                  })}
                />
                {errorWrap(errors?.request?.message)}
              </div>
              <div className="w-100 me-2">
                <label className="form-label">Service Type</label>
                <span className="serviceType_list_dropdown">{renderServiceType()}</span>
              </div>
              <div className="w-100 me-2">
                <label className="form-label">Priority</label>
                <span className="priority_list_dropdown">{renderPriority()}</span>
              </div>
              <div className="FixWithin_dropdown w-100 ">
                <label className="form-label">Fix Within</label>

                <span>{renderFixWithin()}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="description w-100">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  {...register("description", {
                    required: MESSAGES.DESCRIPTION_REQUIRED,
                    pattern: {
                      //value: /^\w+$ /,
                      value: /^[\w\s-]+$/,
                      message: "",
                    },
                  })}
                />
              </div>

              <div>
                <div className="p-1">
                  <label htmlFor="file-input">
                    <img src={attachment} alt="seqrops logo" />
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept=".png,.jpeg,.jpg"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    multiple
                  />
                </div>
              </div>
            </div>
            <div className="img_preview my-3">
              {imagePreviews.map((preview, index) => (
                <div
                  className="img_innerBox"
                  key={index}
                  style={{ display: "inline-block" }}
                >
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      marginRight: "10px",
                    }}
                  />
                  <img
                    src={closeButton}
                    alt="Close Button"
                    onClick={() => removeImage(index)}
                  />
                </div>
              ))}
            </div>
            {/* ERROR MSG FOR DESCRIPTION IS PLACED HERE BECAUSE OF UI DESIGN */}
            {errorWrap(errors?.description?.message)}
          </div>
          {/* <div className="serviceRequest-ticket col-sm-4">
            <img
              src={highPriorityTicket}
              alt="High Priority Ticket"
              className="mb-5"
            />

            <h4>Overdue tickets</h4>

            <h4>High priority tickets</h4>

            <button className="btn btn-primary w-100 mt-5">View More</button>
          </div> */}
        </div>
        {/* <CreateInstancePopUp
          addCommentPopUp={addCommentPopUp}
          addCommentPopUpTrigger={setAddCommentPopUp}
          setValue={setValue}
          setSuccess={setSuccess}
        ></CreateInstancePopUp> */}
      </div>
    </>
  );
}

export default ServiceRequest;
