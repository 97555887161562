import React, { useEffect, useState } from "react";
import ServiceRequestTable from "../serviceRequest-table.component/ServiceRequest_table";
import sequropsBackIcon from "../assest/images/sequrops_back_icon.svg";

import "./serviceRequestListing.scss";
import { useNavigate } from "react-router-dom";
import { serviceRequestAction } from "../store/service-request/service-request.action";

function ServiceRequestListing() {
  const navigate = useNavigate();
  const navigateToServiceRequest = () => {
    navigate("/addServiceRequest");
  };
  const [filteredData, setFilteredData] = useState<any>([]);

  return (
    <>
      <div className="container">
        <div className="header d-flex my-5">
          <div className="p-1">
            <a href="/">
              <img src={sequropsBackIcon} alt="seqrops logo" />
            </a>
          </div>
          <div className="px-4">
            <h2>Service Request </h2>
          </div>
          <div className=" ms-auto">
            <button
              className="btn btn-primary"
              onClick={navigateToServiceRequest}
            >
              Create Request
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        {/* <div className="dropDown-bar d-flex my-3 p-2">
          <div className="ms-auto d-flex">
            <p className="me-4">State</p>
            <p className="me-4">Priority</p>
            <p className="me-4">Assign to</p>
          </div>
        </div> */}
        <div className="service_request_table_row">
          {/* <ServiceRequestTable /> */}
          <ServiceRequestTable filteredData={filteredData} />
        </div>
      </div>
    </>
  );
}

export default ServiceRequestListing;
