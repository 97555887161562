import CommentListItem from "../comment-list-item/comment-list-item"
import { IComment } from "./service-comment-list.interface"
import "./service-comments-list.scss"

function ServiceCommenetsList({
  comments
}:{
  comments: []
  }) {
  return (
    <>
    <div className="service-comments-list-table-wrapper">
        <div className="container">
          <div className="col comments-head-section">
          </div>
          <div className="comments-list-section">
            {comments?.length > 0 && 
            comments?.map((comment: IComment)=>{
              return(
                <>
                  <CommentListItem comment={comment}/>
                </>
              )
            })}
          </div>
        </div>
    </div>
    </>
  )
}

export default ServiceCommenetsList
