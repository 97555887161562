import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISeqrOpsState } from "./seqr-ops.interface";

//initial state
const initialState: ISeqrOpsState = {
  loadingState: {
    loading: false,
  },
  loginState: {
    isLoggedIn: false,
  },
  navigationState: {},
};


const seqrOpsSlice = createSlice({
  name: "seqrOpsSlice",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingState = {
        ...state.loadingState,
        loading: action.payload,
      };
    },
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loginState = {
        ...state.loginState,
        isLoggedIn: action.payload,
      };
  },
}});

export const { setLoading, setLogin } = seqrOpsSlice.actions;
export default seqrOpsSlice.reducer;
