import seqropsLogo from "../assest/images/conwork_FSM_logo.svg";
import seqropsSettingsIcon from "../assest/images/seqrops-settings_icon.svg";
import seqropsMenuBtn from "../assest/images/seqrops_menu_icon.svg";
import seqropsHomeBtn from "../assest/images/seqrops_home_btn.svg";
import seqropsAssetModelingBtn from "../assest/images/asset_model_icon.svg";
import seqropsAssetManagementBtn from "../assest/images/asset_management_icon.svg";
import seqropsDeviceManagemenetBtn from "../assest/images/device_management_icon.svg";
import managementDashboardIcons from "../assest/images/management_dashboard_icon.svg";
import operationalDashboardIcons from "../assest/images/operational_dashboard.svg";
import seqropsLogoutBtn from "../assest/images/seqrops-exit-btn.svg";
import "./navbar.scss";
import { Tooltip } from "@mui/material";
import { assetKeycloakActions } from "../store/keycloak/asset-keycloak.action";
import { setLogin } from "../store/seqr-ops/seqr-ops.slice";
import {
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  CLEAR_CLIENT_SCOPES,
  IFRAME_ID,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "../config/default.config";
import {
  ILogoutTokenPayload,
  ITokenDetails,
  IUserAuthorizations,
} from "../store/keycloak/asset-keycloak.interface";
import {
  addAuthorizations,
  setTokens,
} from "../store/keycloak/asset-keycloak.slice";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { encryptUtils } from "../utils/encryption.utils";
import { OverlayPanel } from "primereact/overlaypanel";

function Navbar(props: any) {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const [showDialog, setShowDialog] = useState(false);
  const isLoggedIn = useAppSelector(
    (state) => state.seqrOps.loginState.isLoggedIn
  );
  const dispatch = useDispatch<any>();
  let navigate = useNavigate();
  const op = useRef<OverlayPanel>(null);
  const userName = useAppSelector((state) => state.assetKeycloak.username);
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();

  const handleLogOut = async () => {
    let ILogoutTokenPayload: ILogoutTokenPayload = {
      refresh_token: authorozedScopes.refresh_token,
      client_id: authorozedScopes.client_id,
      client_secret: authorozedScopes.client_secret,
    };
    await assetKeycloakActions.logoutToken(ILogoutTokenPayload, setSuccess);
  };

  const setSuccess = (state: boolean) => {
    const ITokenDetails: ITokenDetails = {
      auth_token: "",
      refresh_token: "",
      client_id: "",
      client_secret: "",
      username: "",
      password: "",
    };
    dispatch(setTokens(ITokenDetails));
    dispatch(addAuthorizations(CLEAR_CLIENT_SCOPES));
    dispatch(setLogin(false));
    localStorage.removeItem(SESSION_KEY.LOCAL_STORAGE_KEY);
    clearAllTokenData();
    if (state) {
    }
  };

  const clearAllTokenData = () => {
    let postMsg = {
      [SESSION_KEY.CLEAR_ALL_TOKEN_DATA_KEY]: "cl3ar",
      type: SESSION_TYPE.USER_LOGOUT_TYPE,
    };
    const POST_DOMAIN_DATA = [
      {
        frameId: IFRAME_ID.SUITE_IFRAME_ID,
        url: `${process.env.REACT_APP_SUITE_URL}`,
      },
    ];
    for (let i = 0; i < POST_DOMAIN_DATA.length; i++) {
      console.log("cross domain test in qa fsm ::: ", POST_DOMAIN_DATA[i]);
      const iframe = document.getElementById(
        POST_DOMAIN_DATA[i].frameId
      ) as HTMLIFrameElement;
      iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA[i].url);
    }
    window.location.href = ASSET_SUITE_LOGIN_URL;
  };

  const hideDialog = () => {
    setShowDialog(false);
  };

  const confirmLogout = () => {
    setShowDialog(true);
  };

  const footer = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleLogOut}
        autoFocus
      />
    </div>
  );

  const handleDashboardClick = (domain: string) =>{

    let postMsg = {
        type: SESSION_TYPE.DOMAIN_PASS_TYPE,
        domain: encryptUtils.encryptURL(domain)
      };
    const iframe = document.getElementById(
        IFRAME_ID.DASHBOARD_IFRAME_ID
        ) as HTMLIFrameElement;
      iframe.contentWindow?.postMessage(postMsg, `${process.env.REACT_APP_DASHBOARD_URL}`);
}

const handleHomeClick = (domain: string) =>{

  let postMsg = {
      type: SESSION_TYPE.DOMAIN_PASS_TYPE,
      domain: encryptUtils.encryptURL(domain)
    };
  const iframe = document.getElementById(
      IFRAME_ID.SUITE_IFRAME_ID
      ) as HTMLIFrameElement;
    iframe.contentWindow?.postMessage(postMsg, `${process.env.REACT_APP_SUITE_URL}`);
}

  useEffect(() => {
    let tokenDataEncrypted: any = localStorage.getItem(
      SESSION_KEY.LOCAL_STORAGE_KEY
    );
    let tokenData = encryptUtils.decryptURL(tokenDataEncrypted);
    if (tokenData !== null) {
      setUserScopesFromLocalStorage(tokenData?.token?.userAuthorizations);
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="seqrops-navbar-wrapper"  onMouseLeave={(e)=>{
        op.current && op.current?.hide()
      }}>
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex justify-content-between">
              <div className="seqrops-logo-section">
                <img src={seqropsLogo} alt="seqrops logo" />
              </div>
              <div className="seqrops-user-section">
                {/* <button className="seqrops-settings">
                  <img src={seqropsSettingsIcon} alt="seqrops settings logo" />
                </button> */}
                <Tooltip title="menu">
                  <button
                    className="seqrops-settings"
                    onClick={(e) => op.current && op.current?.toggle(e)}
                  >
                    <img src={seqropsMenuBtn} alt="seqrops settings logo" />
                  </button>
                </Tooltip>
              </div>
              <OverlayPanel ref={op}>
                <div className="d-flex align-items-center header-sidebar">
                  <div className="me-auto">
                    <h4>{authorozedScopes?.username}</h4>
                  </div>
                  <div className="logout-section">
                    <span>Logout</span>
                    <img
                      src={seqropsLogoutBtn}
                      alt="Log out"
                      className="img-fluid asset-logout"
                      onClick={() => {
                        confirmLogout();
                      }}
                    />
                  </div>
                </div>
                <div className="seqrops-asset-apps">
                  <div className="row seqrops-asset-apps-row">
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.asset_suite && (
                        <>
                          <div className="col target-app-container" onClick={()=>{handleHomeClick(KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT)}}>
                            <a href={ASSET_URLS.ASSET_SUITE_URL}>
                              <div className="div">
                                <img
                                  src={seqropsHomeBtn}
                                  alt="suite"
                                  className="img-fluid asset-images"
                                />
                                <span>Home</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.asset_models && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.ASSET_MODELS_URL}>
                              <div className="div">
                                <img
                                  src={seqropsAssetModelingBtn}
                                  alt="models"
                                  className="img-fluid asset-images"
                                />
                                <span>Asset Models</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.asset_management && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.ASSET_MANAGEMENT_URL}>
                              <div className="div">
                                <img
                                  src={seqropsAssetManagementBtn}
                                  alt="management"
                                  className="img-fluid asset-images"
                                />
                                <span>Asset Management</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                  </div>
                  <div className="row">
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.device_management && (
                        <>
                          <div className="col target-app-container">
                            <a href={ASSET_URLS.DEVICE_MANAGEMENT_URL}>
                              <div className="div">
                                <img
                                  src={seqropsDeviceManagemenetBtn}
                                  alt="device"
                                  className="img-fluid asset-images"
                                />
                                <span>Device Management</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.operational_dashboard && (
                        <>
                          <div className="col target-app-container" onClick={()=>{handleDashboardClick(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD)}}>
                            <a href={ASSET_URLS.OPERATIONAL_DASHBOARD_URL+"?dashboardType=operational-dashboard"}>
                              <div className="div">
                                <img
                                  src={operationalDashboardIcons}
                                  alt="operational"
                                  className="img-fluid asset-images"
                                />
                                <span>Operations Dashboard</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                    {userScopesFromLocalStorage !== undefined &&
                      userScopesFromLocalStorage.management_dashboard && (
                        <>
                          <div className="col target-app-container" onClick={()=>{handleDashboardClick(KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD)}}>
                            <a href={ASSET_URLS.MANAGEMENT_DASHBOARD_URL+"?dashboardType=management-dashboard"}>
                              <div className="div">
                                <img
                                  src={managementDashboardIcons}
                                  alt="management"
                                  className="img-fluid asset-images"
                                />
                                <span>Management Dashboard</span>
                              </div>
                            </a>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </OverlayPanel>
            </div>
          </div>
        </div>
        <Dialog
          visible={showDialog}
          onHide={hideDialog}
          header="Confirm Logout"
          footer={footer}
          modal
          style={{ width: "350px" }}
        >
          <div>Are you sure you want to logout?</div>
        </Dialog>
      </div>
    </div>
  );
}

export default Navbar;
