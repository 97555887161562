// import { Autocomplete, TextField } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { serviceRequestAction } from "../../store/service-request/service-request.action";

// function EquipmentIdComponent({
//   register,
//   setValue,
//   equipmentModel,
// }: {
//   register: any;
//   setValue: any;
//   equipmentModel: any;
// }) 
// {
// //   const states: any = [{ name: "1" }, { name: "2" }, { name: " 3" },{ name: " 7" }];
// const [states, setStates] = useState<any>([]);
// const  getAllInstancesss ={
   
//     pageNo: 0,
//     pageSize: 5,
//     sortKey: "name",
//     sortOrder: "asc",
//     searchKey: "",
//     modelName: equipmentModel,
//     getAll: true
  
// }
//   useEffect(() => {
//     console.log("equipmentModel ::: ", equipmentModel);
//     serviceRequestAction.getAllInstancess(getAllInstancesss).then((response: any) => {
//       console.log(response , "tableeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
//       const ids = response.data.map((item: any) => item.instanceName);
//       console.log(ids , "Instance name ::::::::::::::::::::::::::::::::::");

//       setStates(ids);

//     });
//   },[equipmentModel]);
//   return (
//     <>
//       <Autocomplete
//         options={states}
//         onChange={(e: any, data: any) => {
//           setValue("instance_name", data);
//         }}
//         getOptionLabel={(option: any) => option}
//         renderInput={(params: any) => {
//           return <TextField {...params} key={params.id} label="" />;
//         }}
//       />
//     </>
//   );
// }

// export default EquipmentIdComponent;


import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { MESSAGES } from "../../config/default.config";

function EquipmentIdComponent({
  register,
  setValue,
  equipmentModel,
  errorWrap,
  formState,
  clearErrors
}: {
  register: any;
  setValue: any;
  equipmentModel: any;
  errorWrap:any;
  formState:any;
  clearErrors:any;
}) 
{
//   const states: any = [{ name: "1" }, { name: "2" }, { name: " 3" },{ name: " 7" }];
const [states, setStates] = useState<any>([]);
 const errors = formState.errors;
// const errorWrap = (errorMsg: string | undefined) => {
//   if (!errorMsg) return null;
//   return <p className="error-message-section">{errorMsg}</p>;
// };
const  getAllInstancesss ={
   
    pageNo: 0,
    pageSize: 5,
    sortKey: "name",
    sortOrder: "asc",
    searchKey: "",
    modelName: equipmentModel,
    getAll: true
  
}
  useEffect(() => {
    console.log("equipmentModel ::: ", equipmentModel);
    serviceRequestAction.getAllInstancess(getAllInstancesss).then((response: any) => {
      console.log(response , "tableeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      const instanceName = response.data.map((item: any) => item.instanceName);
      console.log(instanceName , "Instance name ::::::::::::::::::::::::::::::::::");

      setStates(instanceName);

    });
  },[equipmentModel]);
  return (
    <>
      <Autocomplete
        options={states}
        {...register("instance_name", {
          required: MESSAGES.EQUIPMENT_REQUIRED,
        })}
        onChange={(e: any, data: any) => {
          setValue("instance_name", data);
          clearErrors("instance_name");
        }}
        

        getOptionLabel={(option: any) => option}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.instance_name?.message)}

    </>
  );
}

export default EquipmentIdComponent;


  