import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import Service_Request from "../assest/images/service_request.svg";
import ServiceRequestTable from "../serviceRequest-table.component/ServiceRequest_table";
import { serviceRequestAction } from "../store/service-request/service-request.action";

import { useDispatch } from "react-redux";
import PaginationWrap from "../pagination/pagination-wrap";
import ServiceRequest from "../ServiceRequest/ServiceRequest";
import CreateInstancePopUp from "../modules/fsm-crud/create-service-comment/create-comment";
import highPriorityTicket from "../assest/images/high_priority_ticket_icon.svg";
import overdueTicket from "../assest/images/overdur_ticket_icon.svg";
import filter from "../assest/images/filter_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import StateFilterDataComponent from "./sub-components/state_component";
import PriorityFilterDataComponent from "./sub-components/priority_component";
import { getFilterData } from "../store/service-request/service-request.interface";

function Home() {
  interface IPriorityList {
    highCount: number;
    mediumCount: number;
    lowCount: number;
  }

  interface IDueCountBody {
    today: number;
    nextThreedays: number;
    nextSevenDays: number;
    yesterday: number;
    lastThreeDays: number;
    lastSevenDays: number;
    totalHighPriorityCount: number;
    priority: IPriority;
  }

  interface IPriority {
    high: number;
    medium: number;
    low: number;
  }

  const dispatch = useDispatch<any>();
  // const [prorityList, setProrityList] = useState<IPriorityList>();
  const [overdueList, setOverdueList] = useState<IDueCountBody>({
    today: 0,
    nextThreedays: 0,
    nextSevenDays: 0,
    yesterday: 0,
    lastThreeDays: 0,
    lastSevenDays: 0,
    totalHighPriorityCount: 0,
    priority: {
      high: 0,
      medium: 0,
      low: 0,
    },
  });
  const [paginationPayload, setPaginationPayload] = useState({
    totalRecords: 45,
    totalFilteredRecords: "",
    pageNo: 0,
    pageSize: 0,
    sortKey: "",
    sortOrder: "desc",
    setTotalCount: "",
  });
  let navigate = useNavigate();
  const op = useRef<OverlayPanel>(null);

  const [apiStatus, setApiStatus] = useState<boolean>(false);
  const [modelResponse, setModelResponse] = useState([]);
  const [totalRecords, setTotalRecords] = useState<any>();
  const [register, setregister] = useState();
  const [value, setValue] = useState();
  const [serviceList, setStateList] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>([]);
  // useEffect(() => {
  //   serviceRequestAction
  //     .getAllServiceLists(paginationPayload)
  //     .then((response: any) => {
  //       setModelResponse(response.data || []);
  //       setTotalRecords(response.totalRecords);
  //       setApiStatus(true);
  //     })
  //     .catch(() => {
  //       setModelResponse([]);
  //       setTotalRecords(0);
  //       setApiStatus(true);
  //     });
  // }, [paginationPayload]);
  useEffect(() => {
    const fetchAllServiceLists = async () => {
      try {
        const response = await serviceRequestAction.getAllServiceLists(
          paginationPayload
        );
        setModelResponse(response.data || []);
        setTotalRecords(response.totalRecords);
        setApiStatus(true);
      } catch (error) {
        setModelResponse([]);
        setTotalRecords(0);
        setApiStatus(true);
      }
    };

    fetchAllServiceLists();
  }, [overdueList]);

  useEffect(() => {
    const overdueData = async () => {
      try {
        dispatch(
          serviceRequestAction
            .getOverdueCount()
            .then((response: any) => {
              let dueCount: IDueCountBody = {
                today: response?.today,
                nextThreedays: response?.nextThreeDays,
                nextSevenDays: response?.nextSevenDays,
                yesterday: response?.yesterday,
                lastThreeDays: response?.lastThreeDays,
                lastSevenDays: response?.lastSevenDays,
                totalHighPriorityCount: response?.totalHighPriorityCount,
                priority: response?.priorityDto,
              };
              setOverdueList(dueCount);
            })
            .catch((error) => {})
        );
      } catch (error) {}
    };
    overdueData();
  }, []);
  const [FilterDatasPayload, setFilterDatasPayload] = useState<getFilterData>({
    sortKey: "",
    sortOrder: "ASC",
    stateKey: "",
  });

  useEffect(() => {
    const response = serviceRequestAction
      .getFilterDatas(FilterDatasPayload)
      .then((response) => {
        setFilteredData(response?.data);
        console.log(
          "HEREEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE ::: ",
          response?.data
        );
      });
  }, [FilterDatasPayload]);
  // useEffect(() => {
  //   const priorityFilterData = async () => {
  //     try {
  //       dispatch(
  //         serviceRequestAction
  //           .getFilterDatas(FilterDatas)
  //           .then((response: any) => {
  //             setOverdueList(response);
  //             console.log("Priority Filter Data :::::::: in home", response);
  //           })
  //           .catch((error) => {
  //             console.log("erroorrrrrrrrrrrrrrrrrrrrr", error);
  //           })
  //       );
  //     } catch (error) {
  //       console.log("Error in fetching Priority Filter Data count in home");
  //     }
  //   };
  //   priorityFilterData();
  // }, []);
  const renderPriority = () => {
    return (
      <PriorityFilterDataComponent
        register={register}
        setValue={setValue}
        setSortKey={(sortKey: string) =>
          setFilterDatasPayload((prevFilterDatas: any) => ({
            ...prevFilterDatas,
            sortKey: sortKey,
          }))
        }
      />
    );
  };
  const renderState = () => {
    return (
      <StateFilterDataComponent
        register={register}
        setValue={setValue}
        setStatetKey={(stateKey: string) =>
          setFilterDatasPayload((prevFilterDatas: any) => ({
            ...prevFilterDatas,
            stateKey: stateKey,
          }))
        }
      />
    );
  };

  const location = useLocation();
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, [location]);

  return (
    <>
      <div className="my-5">
        <div className="container">
          <div className="row">
            <div className="tickets-div d-flex justify-content-between">
              <div className="w-85 me-5">
                <div className="highPriorityTickets d-flex align-items-center">
                  <div>
                    <img src={highPriorityTicket} alt="High Priority Ticket" />
                  </div>
                  <div className="p-2 mt-3">
                    <h3>
                      High Priority Tickets{" "}
                      <span>{overdueList?.totalHighPriorityCount}</span>
                    </h3>
                    <div className="high-Priority-count d-flex">
                      <div className="">
                        <h6>
                          Today <span>{overdueList?.today}</span>
                        </h6>
                      </div>
                      <div className="">
                        <h6>
                          Next 3 days <span>{overdueList?.nextThreedays}</span>
                        </h6>
                      </div>
                      <div className="">
                        <h6>
                          Next 7 days <span>{overdueList?.nextSevenDays}</span>
                        </h6>
                      </div>
                    </div>
                    {/* <div className="p-2">
                      <a href="/">View More</a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="w-100">
                <div className="overdueTickets d-flex align-items-center">
                  <div className="d-flex">
                    <div>
                      <img src={overdueTicket} alt="Over due Ticket" />
                    </div>
                    <div className="p-2 mt-3">
                      <h3>Overdue Tickets</h3>
                      <div className="overdue-count d-flex ">
                        <div className="ms-2">
                          <h6>
                            Yesterday
                            <span>{overdueList?.yesterday}</span>
                          </h6>
                        </div>
                        <div className="ms-2">
                          <h6>
                            Last 3 days
                            <span>{overdueList?.lastThreeDays}</span>
                          </h6>
                        </div>
                        <div className="ms-2">
                          <h6>
                            Last 7 days
                            <span>{overdueList?.lastSevenDays}</span>
                          </h6>
                        </div>
                      </div>
                      {/* <div className="p-2">
                        <a href="/">View More</a>
                      </div> */}
                    </div>
                  </div>
                  <div className="prorityCount">
                    <p>
                      <span className="high">
                        {overdueList?.priority?.high}{" "}
                      </span>
                      High
                    </p>
                    <p>
                      <span className="medium">
                        {overdueList?.priority?.medium}
                      </span>{" "}
                      Medium
                    </p>
                    <p>
                      <span className="low">{overdueList?.priority?.low}</span>{" "}
                      Low
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="middle-layer d-flex justify-content-between align-items-center my-3">
              <div className="">
                <div className="d-flex ">
                  <div className="heading_tickets">
                    <h5>Tickets</h5>
                  </div>
                  <div className="ms-3">
                    <a href="/serviceRequestListing">View all</a>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="d-flex">
                  <div className="Filter ms-auto d-flex align-items-center">
                    {/* to be uncommetted */}
                    {/* <div>
                      <img src={filter} alt="" />
                    </div>
                    <div>
                      <button
                        className="Filetr-button"
                        onClick={(e) => op.current && op.current?.toggle(e)}
                      >
                        Filter
                      </button>
                    </div>
                    <OverlayPanel ref={op}>
                      <div>
                        <div>
                          {" "}
                          <label>Priority</label>
                          {renderPriority()}
                        </div>
                        <div>
                          {" "}
                          <label>State</label>
                          {renderState()}
                        </div>
                      </div>
                    </OverlayPanel> */}
                    {/* to be uncommetted */}
                  </div>
                  <div className="ms-3">
                    <button
                      className="btn btn-outline-dark"
                      onClick={() => {
                        navigate("/addServiceRequest");
                      }}
                    >
                      Create Request
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="service-request-table-section">
            {/* <ServiceRequestTable /> */}
            <ServiceRequestTable filteredData={filteredData} />
          </div>
          <div>
            <PaginationWrap
              paginationPayload={paginationPayload}
              setPaginationPayloadTrigger={setPaginationPayload}
              totalRecords={totalRecords}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
