import { useEffect, useState } from "react";
import "./pagination-wrap.scss";
import { TablePagination } from "@mui/material";

function PaginationWrap(props: any) {
  const [pageNo, setPageNo] = useState(props.paginationPayload.pageNo);

  //handling pageNo change
  const handlePageNoChange = (event: any, value: number) => {
    setPageNo(value);
    props.setPaginationPayloadTrigger({
      ...props.paginationPayload,
      pageNo: value,
    });
  };

  //handle row change
  const handleChangeRowsPerPage = (e: any) => {
    props.setPaginationPayloadTrigger({ ...props.paginationPayload, pageSize: e.target.value });
  };

  return (
    <>
      <div className="seqrops-pagination-wrapper d-flex">
        <div className="seqrops-pageNo-section d-flex container-fluid">
          <div className="seqrops-pageSize-section d-flexx`"></div>
            {/* <TablePagination
              component="div"
              rowsPerPageOptions={props.totalRecords ? [5, 10, 15, 20, 25]: []}
              count={props.totalRecords || 0}
              page={pageNo}
              onPageChange={handlePageNoChange}
              rowsPerPage={props.paginationPayload.pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />  */}
        </div>
      </div>
    </>
  );
}

export default PaginationWrap;
