// import { Autocomplete, TextField } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { serviceRequestAction } from "../../store/service-request/service-request.action";

// function ServiceTypeComponent({
//   register,
//   setValue,
// }: {
//   register: any;
//   setValue: any;
// }) {
//   const dispatch = useDispatch<any>();

//   const [states, setStates] = useState([]);

//   useEffect(() => {
//     const response = async () => {
//       try {
//         const data = serviceRequestAction
//           .getKeyClockBearerToken()
//           .then((data: any) => {
//             console.log("home :::: ", data);
//             const usernames = data.data.map((item: any) => item.username);
//             console.log("username :::::", usernames);
//             setStates(usernames);
//           });
//       } catch (error) {
//         console.log("Error is listing Assign To ::: error =>", error);
//       }
//     };
//     response();
//   }, []);

//   return (
//     <>
//       <Autocomplete
//         {...register("assign_to_name", {})}
//         options={states}
//         onChange={(e: any, data: any) => {
//           setValue("assign_to_name", data);
//         }}
//         renderInput={(params: any) => {
//           return <TextField {...params} key={params.id} label="" />;
//         }}
//       />
//     </>
//   );
// }

// export default ServiceTypeComponent;
import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { MESSAGES } from "../../config/default.config";

function ServiceTypeComponent({
  register,
  setValue,
  errorWrap,
  formState,
  clearErrors,
}: {
  register: any;
  setValue: any;
  errorWrap: any;
  formState: any;
  clearErrors:any;
}) {
  const dispatch = useDispatch<any>();

  const [states, setStates] = useState([]);
  const errors = formState.errors;

  useEffect(() => {
    const response = async () => {
      try {
        const data = serviceRequestAction
          .getKeyClockBearerToken()
          .then((data: any) => {
            console.log("home :::: ", data);
            const usernames = data.data.map((item: any) => item.username);
            console.log("username :::::", usernames);
            setStates(usernames);
          });
      } catch (error) {
        console.log("Error is listing Assign To ::: error =>", error);
      }
    };
    response();
  }, []);

  return (
    <>
      <Autocomplete
        {...register("assign_to_name", {
          required: MESSAGES.ASSIGN_TO_REQUIRED,
        })}
        options={states}
        onChange={(e: any, data: any) => {
          setValue("assign_to_name", data);
          clearErrors("assign_to_name");
        }}
        getOptionLabel={(option: any) => option}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
      {errorWrap(errors?.assign_to_name?.message)}
    </>
  );
}

export default ServiceTypeComponent;

