import axios from "axios";
import {
  ASSET_MANAGEMENT,
  FILE_SERVICE,
  FSM_API,
  KEYCLOAK_CLIENTS,
  KEYCLOAK_GRANT_TYPES,
  token_KEY_CLOCK,
} from "../../config/default.config";
import {
  IAddServiceRequestReq,
  ICommentsPayload,
  IGetAllServiceRequest,
  IProirtyCount,
  IUpdateServiceRequestReq,
  getAllInstances,
  getAllServiceList,
  getFilterData,
} from "./service-request.interface";
import { request } from "http";
import { commonUtils } from "../../utils/common.utils";

const fetchAllServiceRequest = async () => {
  const response = await axios.get(FSM_API.LIST_ALL_FSM);
  return response.data;
};

const getAllServiceRequest = async () => {
  const response = await axios.get(FSM_API.LIST_ALL_FSM);
  console.log("response ::: ", response);
  return response.data;
};

const getServiceRequestById = async (id: number) => {
  const response = await axios.get(
    commonUtils.formatString(FSM_API.GET_FSM_BY_ID, id)
  );
  return response.data;
};

const addServiceRequest = async (
  IAddServiceRequestReq: IAddServiceRequestReq
) => {
  const response = await axios.post(FSM_API.CREATE_FSM, IAddServiceRequestReq);
  return response.data;
};

const updateServiceRequest = async (
  IUpdateServiceRequestReq: IUpdateServiceRequestReq
) => {
  const response = await axios.put(
    FSM_API.UPDATE_FSM,
    IUpdateServiceRequestReq
  );
  return response.data;
};

const deleteServiceRequestById=async (id:number)=>{
  const respone =await axios.delete(
    commonUtils.formatString( FSM_API.DELETE_FSM+id)    
  );
  console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii", id);

  return respone;
}

const getPriorityCount = async () => {
  const response = await axios.post(FSM_API.GET_PRIORITY_COUNT);
  console.log(response.data.data, "its service data");

  return response.data.data;
};
const getOverdueCount = async () => {
  const response = await axios.get(FSM_API.GET_OVERDUE_COUNT);
  return response.data?.data;
};
//pagination api
const getAllServiceLists = async (getAllServiceList: getAllServiceList) => {
  const response = await axios.post(
    FSM_API.GET_ALL_SERVICELIST,
    getAllServiceList
  );
  return response.data;
};

const getReference = async () => {
  const response = await axios.get(FSM_API.GET_FILE_REFERENCE);
  return response;
};
const fileUpload = async (formdata: FormData) => {
  const response = await axios.post(FILE_SERVICE.FILE_UPLOAD, formdata);
  return response;
};

const getAllEquipmentModels = async () => {
  const response = await axios.get(ASSET_MANAGEMENT.GET_ALL_EQUIPMENT_MODELS);
  return response.data.data;
};

const getAllInstancess = async (getAllInstances: getAllInstances) => {
  const response = await axios.post(
    ASSET_MANAGEMENT.GET_ALL_INSTANCE,
    getAllInstances
  );
  console.log("its serviceeeeeeeeeee", response.data);
  return response.data.data;
};

const getServiceRequestCommentsById = async (requestId: number) => {
  const response = await axios.get(
    FSM_API.GET_SERVICE_COMMENTS_BY_ID + requestId
  );
  return response.data.data;
};
// const getKeyClockList=async()=>{
//   const response=await axios.post(token_KEY_CLOCK.GET_KEY_CLOCK)
// }

const getKeyClockBearerToken = async () => {
  const data = new URLSearchParams();
  data.append("grant_type", KEYCLOAK_GRANT_TYPES.PASSWORD);
  data.append("client_id", KEYCLOAK_CLIENTS.ASSET_SUITE);
  data.append("client_secret", "uJoJbbGqdUAWJZpLJzSHqGqMaQeCzbP4");
  data.append("password", "hirosh");
  data.append("username", "hirosh");

  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await axios.post(
      token_KEY_CLOCK.GET_KEY_CLOCK_BEARER_TOKEN,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.log("Error in fetching KEY CLOCK Access Token in service");
  }
};
// const getKeyClockUserList= async ()=>{
//   const response =await axios.get(token_KEY_CLOCK.GET_KEY_USER_LIST);
//   return response;
// }
const getKeyClockUserList = async (accessToken: any) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Add the bearer token here
    },
  };
  try {
    console.log("hiiiiiiiiiiiiiiiiiii", config);

    const response = await axios.get(token_KEY_CLOCK.GET_KEY_USER_LIST, config);
    console.log("kye clock list  ::::::", response);

    return response;
  } catch (error) {
    console.log("Error in fetching KEY CLOCK User List in service");
    console.log(error);
  }
};
const getFilterDatas = async (getFilterData: getFilterData) => {
  const response = await axios.post(FSM_API.GET_FILTER_DATA, getFilterData);
  console.log("Done in service:::::::::" ,response)
  return response;
};

const addComments = async (ICommentsPayload: ICommentsPayload) => {
  const response = await axios.post(FSM_API.ADD_COMMENTS, ICommentsPayload);
  console.log("commenets in service :::::::::" ,response)
  return response;
};

const getCustomers = async () => {
  const response = await axios.get(FSM_API.GET_CUSTOMERS);
  console.log("Done in service:::::::::" ,response)
  return response;
};
export const serviceRequestService = {
  fetchAllServiceRequest,
  addServiceRequest,
  getAllServiceRequest,
  getServiceRequestCommentsById,
  getServiceRequestById,
  updateServiceRequest,
  getPriorityCount,
  getAllServiceLists,
  getReference,
  addComments,
  fileUpload,
  getAllEquipmentModels,
  getAllInstancess,
  getOverdueCount,
  getKeyClockBearerToken,
  getKeyClockUserList,
  getFilterDatas,
  getCustomers,
  deleteServiceRequestById,
};
