import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import serviceRequestSlice from "./service-request/service-request.slice";
import seqrOpsSlice from "./seqr-ops/seqr-ops.slice";
import assetKeycloakSlice from "./keycloak/asset-keycloak.slice";    

export const store = configureStore({
  reducer: {
    seqrOps: seqrOpsSlice,
    assetKeycloak: assetKeycloakSlice,
    serviceRequest: serviceRequestSlice 
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
