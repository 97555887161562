import React from 'react'

function ErrorPage() {
  return (
    <div>
     <h1> ERROR ! PAGE NOT FOUND </h1>
    </div>
  )
}

export default ErrorPage
