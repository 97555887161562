// import { Autocomplete, TextField } from "@mui/material";
// import React from "react";

// function FixWithComponent({
//   register,
//   setValue,
// }: {
//   register: any;
//   setValue: any;
// }) {
//   const states: any = [{ name: "1 day",value: 1}, { name: "3 days" ,value: 3},{ name: "7 days",value: 7 }];
//   const handleOnChange = (e: any, data: any) => {
//     const value = data?.value || null; // Get the selected value or set it as null if not available
//     setValue("fix_within", value);
//   };
//   return (
//     <>
//       <Autocomplete
//         options={states}
//         // onChange={(e: any, data: any) => {
//         //   setValue("fix_within", data.name);
//         // }}
//         onChange={handleOnChange}
//         getOptionLabel={(option: any) => option.name}
//         renderInput={(params: any) => {
//           return <TextField {...params} key={params.id} label="" />;
//         }}
//       />
//     </>
//   );
// }

// export default FixWithComponent;
import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { MESSAGES } from "../../config/default.config";

function FixWithComponent({
  register,
  setValue,
  errorWrap,
  formState,
  clearErrors,

}: {
  register: any;
  setValue: any;
  errorWrap:any;
  formState:any;
  clearErrors:any;

}) {
  const states: any = [{ name: "1 day",value: 1}, { name: "3 days" ,value: 3},{ name: "7 days",value: 7 }];
  const errors = formState.errors;

  const handleOnChange = (e: any, data: any) => {
    const value = data?.value || null; // Get the selected value or set it as null if not available
    setValue("fix_within", value);
    clearErrors("fix_within");

  };
  return (
    <>
      <Autocomplete
       {...register("fix_within", {
        required: MESSAGES.FIX_WITHIN_REQUIRED,
      })}
        options={states}
        // onChange={(e: any, data: any) => {
        //   setValue("fix_within", data.name);
        // }}
        onChange={handleOnChange}
        getOptionLabel={(option: any) => option.name}
        renderInput={(params: any) => {
          return <TextField {...params} key={params.id} label="" />;
        }}
      />
                  {errorWrap(errors?.fix_within?.message)}

    </>
  );
}

export default FixWithComponent;
