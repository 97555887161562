import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAddServiceRequestSlice } from "./service-request.interface";

const initialState: IAddServiceRequestSlice = {
  serviceDetails: [],
};

const serviceRequestSlice = createSlice({
  name: "serviceRequest",
  initialState: initialState,
  reducers: {
    setServiceRequest: (state, action: PayloadAction<any>) => {
      state.serviceDetails = [...action.payload];
    },
  },
});
export const { setServiceRequest } = serviceRequestSlice.actions;

export default serviceRequestSlice.reducer;
