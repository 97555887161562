import jwt_decode from "jwt-decode";
import { addAuthorizations } from "./asset-keycloak.slice";
import { IValidateTokenPayload } from "./asset-keycloak.interface";
import { ASSET_SCOPES, FIELD_SERVICE_MANAGEMENT_SCOPE, MESSAGES } from "../../config/default.config";
import { store } from '../../store/index';
import { toasterUtils } from "../../utils/toaster.utils";

const processToken = (IValidateTokenPayload : IValidateTokenPayload, refresh_token: string) =>{

    let auth_token = IValidateTokenPayload.token;

    let decoded : any = jwt_decode(auth_token);
    let resources = Object.keys(decoded.resource_access);
    if(!resources.includes(FIELD_SERVICE_MANAGEMENT_SCOPE)){
        toasterUtils.showError(MESSAGES.USER_UNAUTHORIZED);
    }
    for(let scope of resources ){
        if(ASSET_SCOPES.includes(scope)){
            store.dispatch(addAuthorizations(scope))
        }
    }
}

export const TokenServices = {
    processToken,
  };
  