import { IComment } from "../service-comments-list/service-comment-list.interface"
import "./comment-list-item.scss"

function CommentListItem({
    comment
}:{
    comment: IComment
}) {

const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <>
    <div className="comment-list-item-wrapper">
        <div className="container-fluid">
            <div className="row">
                <div className="col username">
                <span>{comment?.user}</span>
                </div>
                <div className="col created-date">
                <span>Create date : {formatDateTime(comment?.postedAt)}</span>
                </div>
            </div>
            <div className="row">
                <div className="col comment-area">
                <p>{comment?.comments}</p>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default CommentListItem
