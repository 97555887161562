import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { serviceRequestAction } from "../../store/service-request/service-request.action";
import { MESSAGES } from "../../config/default.config";

function CustomerComponent({
    register,
    setValue,
    errorWrap,
    formState,
    clearErrors,
}: {
    register: any;
    setValue: any;
    errorWrap: any;
    formState: any;
    clearErrors: any;
}) {
    const dispatch = useDispatch<any>();

    const [states, setStates] = useState([]);
    const errors = formState.errors;

    useEffect(() => {
        const response = async () => {
            try {
                const data = serviceRequestAction.getCustomers().then((response: any) => {

                    const name = response.data.result.map((item: any) => item.name);
                    setStates(name);
                });
            } catch (error) {
            }
        };
        response();
    }, []);

    return (
        <>
            <Autocomplete
                {...register("customer_name", {
                    required: MESSAGES.CUSTOMER_NAME_REQUIRED,
                })}
                options={states}
                onChange={(e: any, data: any) => {
                    setValue("customer_name", data);
                    clearErrors("customer_name");
                }}
                getOptionLabel={(option: any) => option}
                renderInput={(params: any) => {
                    return <TextField {...params} key={params.id} label="" />;
                }}
            />
            {errorWrap(errors?.customer_name?.message)}
        </>
    );
}

export default CustomerComponent;

